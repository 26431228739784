html {
    width: 100vw;
    height: 100vh;
}

.home {
    // width: 100vw;
    // height: 100vh;

    header {
        width: 120rem;
        height: 5.5rem;
        background: #FFFFFF;
        padding: 0 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 14.7356rem;
            height: 3rem;
        }

        div {
            display: flex;

            .ant-btn {
                width: 6.75rem;
                height: 2.875rem;
                border: .0625rem solid rgba(232, 233, 233, 1);
                border-radius: .75rem;
                font-family: PingFangSC-Regular;
                font-size: 1.125rem;
                letter-spacing: 0;
                text-align: center;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    color: #FFFFFF;
                }
            }

            .ant-btn-default {
                margin-right: 1.5rem;

                a {
                    color: #333333;
                }
            }
        }
    }

    .ant-carousel {
        .slick-slide img {
            width: 100%;
        }
    }

    .bannerBottom {
        width: 99.375rem;
        height: 20.8125rem;
        background: #FFFFFF;
        box-shadow: 0 .5rem 2.0625rem 0 rgba(2, 84, 207, 0.20);
        border-radius: 1rem;
        position: relative;
        top: -3.75rem;
        left: 10.3125rem;
        padding: 3.75rem 7.3594rem 0 8.0156rem;

        div {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            div {
                width: 10.125rem;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0;
                    font-family: PingFangSC-Semibold;
                    font-size: 2.5875rem;
                    color: #1F78FF;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 2.5875rem;
                    font-weight: 600;
                }

                span {
                    font-family: PingFangSC-Regular;
                    font-size: 1.6819rem;
                    color: #D4D4D4;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 2.5875rem;
                    font-weight: 400;
                }
            }

            div:nth-child(3) {
                width: 8.4375rem;
            }

            div:nth-child(4) {
                width: 8.4375rem;
            }
        }

        .div2 {
            padding: 3.1869rem 0 0 5.8788rem;
            justify-content: flex-start;

            div:nth-child(1) {
                width: 19rem;
                margin-right: 2.5387rem;
            }

            div:nth-child(2) {
                width: 15.1875rem;
                margin-right: 5.4769rem;
            }

            div:nth-child(3) {
                width: 10.125rem;
                margin-right: 6.5644rem;
            }

            div:nth-child(4) {
                width: 11.8125rem;
            }
        }
    }

    main {
        padding: 0 10.3125rem;

        h1 {
            width: 45.875rem;
            height: 4.375rem;
            font-family: PingFangSC-Medium;
            font-size: 3.125rem;
            color: #5D5D5D;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            margin: 0 auto;
        }

        .div1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3.75rem;

            div {
                width: 38.75rem;

                p {
                    margin: 0;
                }

                p:nth-child(1) {
                    font-family: PingFangSC-Medium;
                    font-size: 1.75rem;
                    color: #333333;
                    letter-spacing: 0;
                    font-weight: 500;

                    img {
                        width: 2rem;
                        height: 2.1875rem;
                    }
                }

                p:nth-child(2) {
                    margin: 1.5625rem 0;
                    font-family: PingFangSC-Semibold;
                    font-size: 2.375rem;
                    color: #333333;
                    letter-spacing: 0;
                    font-weight: 600;
                }

                p:nth-child(3) {
                    font-family: PingFangSC-Light;
                    font-size: 1.5rem;
                    // color: #5D5D5D;
                    color: black;
                    letter-spacing: .0375rem;
                    line-height: 2.625rem;
                    font-weight: 200;
                }
            }

            img {
                width: 35.7169rem;
                height: 30.375rem;
            }
        }
    }

    .main2 {
        padding: 0 10.3125rem;
        display: flex;
        justify-content: space-between;
        margin: 8.4856rem 0 0 0;

        .div1 {
            display: flex;

            img {
                width: 5rem;
                height: 5rem;
                margin-right: 1.5313rem;
            }

            div {
                p {
                    width: 25rem;
                    font-size: 1.75rem;
                    color: #333333;
                    letter-spacing: 0;
                    margin: 0;
                }

                p:nth-child(1) {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                }

                p:nth-child(2) {
                    font-family: PingFangSC-Semibold;
                    font-weight: 600;
                    margin: 1.625rem 0 1.4375rem;
                }

                p:nth-child(3) {
                    font-family: PingFangSC-Thin;
                    font-size: 1.5rem;
                    color: #5D5D5D;
                    letter-spacing: .1125rem;
                    line-height: 2.625rem;
                    font-weight: 200;
                }
            }
        }
    }

    .main3 {
        background-color: #E2F7FE;
        margin-top: 5rem;
        padding: 4.0625rem 10.3125rem 7.125rem;
        background-image: url('../../img/4.png');
        background-size: 100%;

        h2 {
            width: 12.5rem;
            height: 3.8125rem;
            font-family: AlimamaShuHeiTi-Bold;
            font-size: 3.125rem;
            color: #1F78FF;
            letter-spacing: 0;
            text-align: center;
            font-weight: 700;
            margin: 0 auto;
        }

        .main31 {
            margin-top: 4.0625rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .div1 {
                width: 32.25rem;
                min-height: 17.375rem;
                display: flex;
                justify-content: center;
                background: #FFFFFF;
                box-shadow: 0 0 .9375rem 0 rgba(0, 0, 0, 0.03);
                border-radius: .75rem;
                margin-bottom: 1.3125rem;
                padding-top: 2rem;

                img {
                    width: 5rem;
                    height: 5rem;
                    margin-right: 1.5313rem;
                }

                div {
                    p:nth-child(1) {
                        font-family: PingFangSC-Medium;
                        font-size: 1.625rem;
                        color: #333333;
                        letter-spacing: 0;
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    p:nth-child(2) {
                        font-family: PingFangSC-Light;
                        font-size: 1.375rem;
                        // color: #C2C2C2;
                        color: black;
                        letter-spacing: .0056rem;
                        font-weight: 200;
                    }
                }
            }

            .div1:nth-child(3) {
                div {
                    p:nth-child(2) {
                        font-size: 1.2rem;
                        line-height: 2.635rem;
                    }
                }
            }
        }
    }

    footer {
        padding: 3.125rem 10.1875rem 1.5rem;
        background-image: linear-gradient(90deg, #339DF6 0%, #1466E7 100%);
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            img {
                width: 15.7881rem;
                height: 3.35rem;
                margin-bottom: 2.56rem;
            }

            p {
                margin: 0;

                a {
                    color: #B2D9FF;
                }
            }

            .p1 {
                font-family: PingFangSC-Medium;
                font-size: 1.5rem;
                color: #FFFFFF;
                letter-spacing: .0063rem;
                font-weight: 500;
            }

            .p2 {
                font-family: PingFangSC-Light;
                font-size: 1.375rem;
                color: #B2D9FF;
                letter-spacing: .0056rem;
                font-weight: 200;
                margin: 1.125rem 0 1rem;
            }

            .p4 {
                font-family: PingFangSC-Light;
                font-size: 1.125rem;
                color: #B2D9FF;
                letter-spacing: .005rem;
                text-align: center;
                font-weight: 200;
                margin-top: 3.3125rem;
            }
        }

        div:nth-child(2) {
            img {
                width: 12.8125rem;
                height: 12.8125rem;
            }
        }
    }

    .ziXun {
        height: 12.875rem;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        position: fixed;
        right: 1%;
        bottom: 35%;

        .img1 {
            width: 12.875rem;
            height: 12.875rem;
            display: none;
        }

        .ziXunDiv {
            padding: .75rem 1rem;
            background: #FFFFFF;
            box-shadow: 0 0 1.4375rem 0 rgba(0, 0, 0, 0.07);
            border-radius: 1.3125rem;
            font-family: PingFangSC-Regular;
            font-size: .875rem;
            color: #5D5D5D;
            letter-spacing: .0219rem;
            text-align: center;
            font-weight: 400;
            margin-left: .625rem;

            img {
                width: 1.25rem;
                height: 1.25rem;
                margin-right: .625rem;
            }
        }

        .ziXunDiv:hover {
            cursor: pointer;
        }

        .ziXunDiv:hover+.img1 {
            width: 12.875rem;
            height: 12.875rem;
            display: block;
        }
    }
}